import { useEffect, useState } from 'react';
import { Autocomplete, Button, ButtonGroup, Checkbox, Chip, createFilterOptions, Fade, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addConditionRun, addConditionRunBlock, addEndpoint, clearEndpointElem, deleteEndpoint, editEndpoint, editEndpointEntry, editEndpointName, editEndpointType, selectDataList, selectEditingEndpoint, selectEndpointElem } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion, } from '../../store/sesRobotSlice';
import { selectChannelList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { ENPOINT_TYPES_LIST } from '../../constants/robotConfigLists';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ConditionEntry from '../ConditionEntry/ConditionEntry';
import ConditionRunBlock from '../ConditionRunBlock/ConditionRunBlock';
import ActionEvent from '../ActionEvent/ActionEvent';
import FormAddingConditionEntry from '../Forms/FormAddingConditionEntry/FormAddingConditionEntry';
import FormAddingConditionRun from '../Forms/FormAddingConditionRun/FormAddingConditionRun';
import FormAddingAction from '../Forms/FormAddingAction/FormAddingAction';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { EndpointType } from '../../types/sesTypes';
import { IConfigEndpointProps } from './ConfigEndpoint.props';
import styles from './ConfigEndpoint.module.scss';

const ConfigEndpoint = ({ showEndpointElem, setShowEndpointElem, containerRef }: IConfigEndpointProps): JSX.Element => {
	const [inputEndpointName, setInputEndpointName] = useState<string>(''); // название конечной точки
	const [selectEndpointType, setSelectEndpointType] = useState<EndpointType>('report'); // тип конечной точки
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал
	const [inputFilterByDataElem, setInputFilterByDataElem] = useState<string[]>([]); // значения для фильтрации по элементам
	const [selectFilterByEntryConditions, setSelectFilterByEntryConditions] = useState<string>(''); // фильтрация по условиям входа в условиях запуска

	const [visibleBlockConfig, setVisibleBlockConfig] = useState<'entry' | 'run' | 'actions'>('entry'); // отображение условий/действий
	const [changeFlg, setChangeFlg] = useState<{ thisIs: boolean, listOfChanges: string[] }>({ thisIs: false, listOfChanges: [] }); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showAlertDialogDel, setShowAlertDialogDel] = useState<boolean>(false); // показ диалогового окна при удалении конечной точки

	const [enableRunConditions, setEnableRunConditions] = useState<boolean>(false); // флаг включения условия запуска
	const [indexAndEntryList, setIndexAndEntryList] = useState<Record<number, string[]>>({}); // индекс блока - список условий входа (для условий запуска)
	const [showAlertDialogDisableRunConditions, setShowAlertDialogDisableRunConditions] = useState<boolean>(false); // показ диалогового окна при выключении условий запуска

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const endpointElem = useAppSelector(selectEndpointElem); // store - конечная точка
	const editingEndpoint = useAppSelector(selectEditingEndpoint); // store - статус изменения конечной точки
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за открытием вкладки
	useEffect(() => {
		// при открытии - вешаем обработчики
		if (showEndpointElem) {
			document.addEventListener('mousedown', closeHandler); // вешаем обработчик на нажатие клавиши мыши
			document.addEventListener('keydown', keyDownHandler); // вешаем обработчик на нажатие клавиши клавиатуры
		}
		// при закрытии
		else {
			dispatch(clearEndpointElem()); // очищаем данные конечной точки
			setVisibleBlockConfig('entry'); // сбрасываем tab
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг возможности сохранения
			// очищаем поля
			setInputEndpointName('');
			setSelectEndpointType('report');
			setEnableRunConditions(false);
			setInputChannel('default');
			setInputFilterByDataElem([]);
			setSelectFilterByEntryConditions('');
		}

		// при закрытии - удаляем обработчики
		return () => {
			document.removeEventListener('mousedown', closeHandler);
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [showEndpointElem]);

	// следим за получением конечной точки
	useEffect(() => {
		// если есть данные - вписываем в поля
		if (endpointElem.endpoint) {
			!inputEndpointName && setInputEndpointName(endpointElem.endpoint.name);
			endpointElem.endpoint.type !== 'report' && setSelectEndpointType(endpointElem.endpoint.type);
			endpointElem.endpoint.run.length > 0 && setEnableRunConditions(true); // если условия есть - включаем флаг
		}
	}, [endpointElem.endpoint]);

	// следим за условиями входа/запуска
	useEffect(() => {
		// если есть условия запуска - ищем и дописываем условия по умолчанию
		if ((endpointElem.endpoint && endpointElem.endpoint.run.length > 0) || enableRunConditions) searchEntryConditionsInRunConditions();
	}, [endpointElem.endpoint?.entry, endpointElem.endpoint?.run]);

	// следим за статусом сохранения конечной точки
	useEffect(() => {
		// если изменение конечной точки прошло успешно
		if (editingEndpoint.status === RequestStatus.IDLE && editingEndpoint.error === ResponseStatus.SUCCESS && editingEndpoint.message !== '') {
			if (endpointElem.endpoint) {
				changeFlg.listOfChanges.includes('name') && dispatch(editEndpointName({ endpointId: endpointElem.endpoint.id, endpointName: inputEndpointName })); // если изменилось имя - меняем в store
				changeFlg.listOfChanges.includes('type') && dispatch(editEndpointType({ endpointId: endpointElem.endpoint.id, endpointType: selectEndpointType })); // если изменился тип - меняем в store
				changeFlg.listOfChanges.includes('entry') && dispatch(editEndpointEntry({ endpointId: endpointElem.endpoint.id, conditionEntry: endpointElem.endpoint.entry })); // если изменились условия входа - меняем в store
			}
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
		}
	}, [editingEndpoint]);

	// функция поиска условий входа в условиях вхождения и добавления их по умолчанию
	const searchEntryConditionsInRunConditions = (): void => {
		const indexAndList: Record<number, string[]> = {}; // индекс блока run условия - список id элементов данных
		// перебираем условия входа
		endpointElem.endpoint?.entry.forEach(entryCondition => {
			const indexFound = endpointElem.endpoint?.run.findIndex((runCondition, idx) => {
				const modifyRunCondition = runCondition.map(conditionBlock => conditionBlock.id); // сокращаем id элементов данных
				return entryCondition.filter(dataElem => modifyRunCondition.includes(dataElem)).length === entryCondition.length && !(idx in indexAndList);
			}); // найденный индекс блока условий запуска, в котором есть условие входа

			if (typeof indexFound === 'number' && indexFound >= 0) {
				!(indexFound in indexAndList) && (indexAndList[indexFound] = entryCondition);
			} else {
				dispatch(addConditionRunBlock(entryCondition)); // добавляем новый блок в run условия с этими entry условиями
				endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
			}
		});
		setIndexAndEntryList(indexAndList);
	};

	// обработчик изменения флага включения условий запуска
	const changeEnableRunConditionsFlgHandler = (value: boolean): void => {
		setEnableRunConditions(value);
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
		if (value) searchEntryConditionsInRunConditions(); // если включено - ищем условия по умолчанию и дописываем при необходимости
		else setShowAlertDialogDisableRunConditions(false); // выключаем диалоговое окно
	};

	// функция, определяющая отфильтрованные параметры, которые будут отображаться при поиске
	const filter = createFilterOptions<string>();

	// обработчик добавления конечной точки
	const addEndpointHandler = (): void => {
		activeRobotId && dispatch(addEndpoint({
			robotId: activeRobotId,
			data: {
				name: inputEndpointName,
				entry: endpointElem.endpoint?.entry || [],
				run: endpointElem.endpoint?.run || [],
				actions: endpointElem.endpoint?.actions || {},
				type: selectEndpointType,
			}
		})); // добавляем точку
	};

	// обработчик сохранения конечной точки
	const editEndpointHandler = (): void => {
		endpointElem.endpoint && activeRobotId && dispatch(editEndpoint({
			robotId: activeRobotId,
			endpointId: endpointElem.endpoint.id,
			data: {
				name: changeFlg.listOfChanges.includes('name') ? inputEndpointName : undefined,
				entry: changeFlg.listOfChanges.includes('entry') ? endpointElem.endpoint.entry : undefined,
				run: changeFlg.listOfChanges.includes('run') ?
					enableRunConditions ? endpointElem.endpoint.run : []
					:
					undefined,
				actions: changeFlg.listOfChanges.includes('actions') ? endpointElem.endpoint.actions : undefined,
				type: changeFlg.listOfChanges.includes('type') ? selectEndpointType : undefined,
			}
		})); // изменяем точку
	};

	// обработчик удаления конечной точки
	const deleteEndpointHandler = (): void => {
		setShowAlertDialogDel(false); // закрываем диалоговое окно
		endpointElem.endpoint && activeRobotId && dispatch(deleteEndpoint({ robotId: activeRobotId, endpointId: endpointElem.endpoint.id })); // удаление конечной точки
		closeHandler(); // закрываем вкладку
	};

	// обработчик добавления условия запуска
	const addConditionRunHandler = (conditionBlockIdx: number): void => {
		dispatch(addConditionRun({ conditionBlockIdx }));
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик нажатия клавиши
	const keyDownHandler = (e: KeyboardEvent): void => {
		if (e.code === 'Escape') closeHandler();
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowEndpointElem(false);
	};

	return (
		<Slide direction="right" in={showEndpointElem} mountOnEnter unmountOnExit container={containerRef.current}>
			<div className={cn(styles.data, {
				[styles.dataMinWidth]: visibleBlockConfig !== 'entry'
			})} onMouseDown={(e) => e.stopPropagation()}>

				{/* загрузка конечной точки */}
				{endpointElem.status === RequestStatus.LOADING &&
					<div className={styles.loading}>
						<ProgressCircle title={translate('spinnerTitle_loading')} />
					</div>
				}

				{/* ошибка получения конечной точки */}
				{(endpointElem.status === RequestStatus.FAILED || endpointElem.error === ResponseStatus.FAILED) &&
					<div className={styles.failed}>
						{translate(endpointElem.message || 'title_loadFailed')}
					</div>
				}

				{/* конечная точка */}
				{endpointElem.endpoint &&
					<Fade in={true} timeout={500}>
						<div className={styles.dataWrapper}>
							<div className={styles.dataTop}>
								<div className={styles.dataTopMain}>
									<div className={styles.dataTopMainBlock}>
										{/* название */}
										<FormControl fullWidth sx={{ marginTop: '8px' }}>
											<TextField
												required
												label={translate('input_name')}
												variant="outlined"
												disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
												value={inputEndpointName}
												onChange={(e) => {
													setInputEndpointName(e.target.value);
													endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('name')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'name'] }));  // ставим флаг о несохраненных данных;
												}}
												InputProps={{
													style: {
														height: 33,
														fontSize: 13,
														color: colorPrimary,
													},
												}}
												InputLabelProps={{
													style: {
														fontSize: 13,
													},
												}}
												sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
											/>
										</FormControl>

										{/* тип */}
										<FormControl sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, marginTop: '8px', flexShrink: 0 }}>
											<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
											<Select
												label={translate('select_type')}
												value={selectEndpointType}
												onChange={(e) => {
													setSelectEndpointType(e.target.value as EndpointType);
													endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('type')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'type'] }));  // ставим флаг о несохраненных данных;
												}}
												disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
												style={{ fontSize: 13, height: 33, color: colorPrimary }}
											>
												{ENPOINT_TYPES_LIST.map(({ type, translation }) =>
													<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
												)}
											</Select>
										</FormControl>
									</div>

									<ButtonGroup fullWidth>
										<Button
											variant={visibleBlockConfig === 'entry' ? "contained" : "outlined"}
											sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
											onClick={() => setVisibleBlockConfig('entry')}
										>
											{translate('button_entryConditions')}
										</Button>
										<Button
											variant={visibleBlockConfig === 'run' ? "contained" : "outlined"}
											sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
											onClick={() => setVisibleBlockConfig('run')}
										>
											{translate('button_runConditions')}
										</Button>
										<Button
											variant={visibleBlockConfig === 'actions' ? "contained" : "outlined"}
											sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
											onClick={() => setVisibleBlockConfig('actions')}
										>
											{translate('button_actions')}
										</Button>
									</ButtonGroup>

									{/* фильтр условий входа */}
									{endpointElem.endpoint.id && visibleBlockConfig === 'entry' &&
										<Autocomplete
											multiple
											freeSolo
											autoHighlight
											openOnFocus
											options={dataElemList.data.map(dataElemItem => dataElemItem.name)}
											value={inputFilterByDataElem}
											onChange={(_, value) => setInputFilterByDataElem(value)}
											filterSelectedOptions
											filterOptions={(options, state) => {
												const filtered = filter(options, state);
												if (state.inputValue.length > 0 && options.findIndex(className => className === state.inputValue) === -1) filtered.push(state.inputValue);
												return filtered;
											}}
											renderInput={(params) =>
												<TextField
													{...params}
													label={translate('input_filterByDataElements')}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											}
											sx={{
												".MuiInputBase-root": { minHeight: 33, fontSize: 13, color: colorPrimary },
												".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "30px!important" },
											}}
											getOptionLabel={option => option}
											renderOption={(props, option, _state, ownerState) => {
												const match = ownerState.options.filter(classItem => classItem === option);
												return (
													<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorPrimary }}>
														{match.length === 0 ?
															<>{translate('selectItem_addFilter')} "{option}"</>
															:
															<>{option}</>
														}
													</li>
												);
											}}
											renderTags={(value: readonly string[], getTagProps) =>
												value.map((option: string, index: number) => (
													<Chip label={option} {...getTagProps({ index })} sx={{ height: 17, color: colorPrimary }} />
												))
											}
										/>
									}

									{/* для таба "условия запуска" */}
									{visibleBlockConfig === 'run' &&
										<>
											{/* включение условий запуска */}
											<FormControlLabel sx={{ overflow: 'hidden', '.MuiTypography-root': { fontSize: 13 }, marginBlock: '-12px' }} control={
												<Checkbox
													checked={enableRunConditions}
													disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
													onChange={(e) => {
														e.target.checked ? changeEnableRunConditionsFlgHandler(true) : setShowAlertDialogDisableRunConditions(true);
													}}
													size='small'
												/>
											} label={translate('checkbox_enableRunConditions')} />

											{/* фильтр условий запуска */}
											{endpointElem.endpoint.id && enableRunConditions &&
												<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
													<InputLabel sx={{ fontSize: 13 }}>{translate('select_filterByEntryCondition')}</InputLabel>
													<Select
														label={translate('select_filterByEntryCondition')}
														value={selectFilterByEntryConditions}
														onChange={(e) => setSelectFilterByEntryConditions(e.target.value)}
														style={{ fontSize: 13, height: 33, color: colorPrimary }}
													>
														<MenuItem value='' sx={{ fontSize: 13, color: colorPrimary }}>
															{translate('selectItem_notSelected')}
														</MenuItem>
														{endpointElem.endpoint.entry.map((entryCondition, idx) =>
															<MenuItem key={entryCondition.toString() + idx} value={entryCondition.toString()} sx={{ fontSize: 13, color: colorPrimary }}>
																{entryCondition.map(dataElem => dataElemList.dictionary[dataElem] || dataElem).join(', ')}
															</MenuItem>
														)}
													</Select>
												</FormControl>
											}
										</>
									}
								</div>

								{visibleBlockConfig === 'entry' &&
									<div className={styles.dataTopEntry}>
										{endpointElem.endpoint.entry.map((condition, idx) => {
											// фильтрация по элементам данных
											const suitableCondition = inputFilterByDataElem.filter(filterName => condition
												.map(dataElemId => dataElemList.dictionary[dataElemId] || dataElemId) // перевод id -> name
												.find(dataElemName => dataElemName.toLowerCase().includes(filterName.toLowerCase()))
											).length === inputFilterByDataElem.length;
											if (suitableCondition) {
												return (
													<ConditionEntry
														condition={condition}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
														idx={idx}
														key={condition.join('') + idx}
													/>
												);
											} else return undefined;
										})}
										{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
											<FormAddingConditionEntry
												changeFlg={changeFlg}
												setChangeFlg={setChangeFlg}
											/>
										}
									</div>
								}

								{visibleBlockConfig === 'run' &&
									<div className={styles.dataTopRun}>
										{enableRunConditions &&
											<>
												{endpointElem.endpoint.run.map((conditionDataBlock, conditionBlockIdx, arrayConditionsData) => {
													// фильтрация по условию входа
													const suitableCondition = selectFilterByEntryConditions === '' || selectFilterByEntryConditions.split(',').filter(dataElemId => conditionDataBlock.map(condition => condition.id).includes(dataElemId)).length === selectFilterByEntryConditions.split(',').length;
													if (suitableCondition) {
														return (
															// блоки ИЛИ
															<ConditionRunBlock
																conditionDataBlock={conditionDataBlock}
																conditionBlockIdx={conditionBlockIdx}
																isDisable={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
																isAvailable={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
																changeFlg={changeFlg}
																setChangeFlg={setChangeFlg}
																conditionFor={{
																	for: 'endpoint',
																	entryList: conditionBlockIdx in indexAndEntryList ? indexAndEntryList[conditionBlockIdx] : undefined,
																}}
																addConditionRunHandler={addConditionRunHandler}
																arrayConditionsData={arrayConditionsData}
																key={conditionDataBlock.toString() + conditionBlockIdx}
															/>
														);
													} else return undefined;
												})
												}
												{endpointElem.endpoint.run.length === 0 ?
													isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
													// форма добавления условия
													<fieldset className={styles.conditionDataBlock}>
														<legend>{translate('title_conditions')}</legend>
														<FormAddingConditionRun
															conditionBlockIdx={endpointElem.endpoint?.run?.length || 0}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
														/>
													</fieldset>
													:
													isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
													// разделитель-кнопка ИЛИ
													<div className={styles.conditionDataBlockLineButton}>
														<span onClick={() => addConditionRunHandler(endpointElem.endpoint?.run.length || 0)}>{translate('title_or').toUpperCase()}</span>
													</div>
												}
											</>
										}
									</div>
								}

								{visibleBlockConfig === 'actions' &&
									<div className={styles.dataTopActions}>
										<div className={styles.dataTopActionsBlock}>
											{/* канал */}
											<FormControl fullWidth>
												<Autocomplete
													freeSolo={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
													options={Array.from(
														new Set(
															(Object.keys(endpointElem.endpoint.actions || {}))
																.concat(channelList.data.map(channel => channel.name), 'default')
														))}
													value={inputChannel}
													onChange={(_, value) => setInputChannel(value ? value : '')}
													noOptionsText={<div className={styles.dataTopActionsNoDataTitle}>{translate('title_notFound')}</div>}
													renderInput={(params) =>
														<TextField
															{...params}
															label={translate('input_channel')}
															onChange={(e) => isAccess(SES.ENDPOINT_EDIT) && setInputChannel(e.target.value)}
															InputLabelProps={{
																style: {
																	fontSize: 13,
																},
															}}
															sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
														/>
													}
													sx={{
														".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
														".MuiInputBase-input": { marginTop: -1 },
													}}
													getOptionLabel={option => option}
													renderOption={(props, option) => {
														return (
															<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																{option}
															</span>
														);
													}}
												/>
											</FormControl>
										</div>

										{/* действия */}
										<div className={styles.dataTopActionsList}>
											{endpointElem.endpoint.actions[inputChannel]?.length > 0 && endpointElem.endpoint.actions[inputChannel].map((action, idx) => (
												<ActionEvent
													key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}`}
													action={action}
													idx={idx}
													channel={inputChannel}
													changeFlg={changeFlg}
													setChangeFlg={setChangeFlg}
													itsAction='endpoint'
												/>
											))}

											{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
												<FormAddingAction
													channel={inputChannel}
													itsAction='endpoint'
													changeFlg={changeFlg}
													setChangeFlg={setChangeFlg}
												/>
											}
										</div>
									</div>
								}
							</div>

							{activeRobotVersion === 'draft' &&
								<div className={styles.dataButtons}>
									{endpointElem.endpoint.id ?
										<>
											{isAccess(SES.ENDPOINT_EDIT) &&
												<FormControl fullWidth>
													<Button
														variant="outlined"
														disabled={!changeFlg.thisIs}
														sx={{ fontSize: 11 }}
														onClick={editEndpointHandler}
													>
														{translate('button_save')}
													</Button>
												</FormControl>
											}
											{isAccess(SES.ENDPOINT_DELETE) &&
												<FormControl fullWidth>
													<Button
														variant="outlined"
														sx={{ fontSize: 11 }}
														onClick={() => setShowAlertDialogDel(true)}
														color='error'
													>
														{translate('button_delete')}
													</Button>
												</FormControl>
											}
										</>
										:
										isAccess(SES.ENDPOINT_ADD) &&
										<FormControl fullWidth>
											<Button
												variant="outlined"
												sx={{ fontSize: 11 }}
												onClick={addEndpointHandler}
											>
												{translate('button_addEndpoint')}
											</Button>
										</FormControl>
									}
								</div>
							}
						</div>
					</Fade>
				}

				{/* выключение условий запуска */}
				<AlertDialog
					showAlertDialog={showAlertDialogDisableRunConditions}
					setShowAlertDialog={setShowAlertDialogDisableRunConditions}
					submitHandler={() => changeEnableRunConditionsFlgHandler(false)}
					title='dialog_disablingRunConditions'
					description='dialog_disablingRunConditionsConfirm'
					name={endpointElem.endpoint?.name}
				/>

				{/* удаление конечной точки */}
				<AlertDialog
					showAlertDialog={showAlertDialogDel}
					setShowAlertDialog={setShowAlertDialogDel}
					submitHandler={deleteEndpointHandler}
					title='dialog_deleteEndpoint'
					description='dialog_deleteEndpointConfirm'
					name={endpointElem.endpoint?.name}
				/>
			</div>
		</Slide>
	);
};

export default ConfigEndpoint;
