import { memo } from 'react';
import TagCloud from 'react-tag-cloud';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDataElem, getEndpoint, selectDataList, selectEndpointList } from '../../../store/sesSlice';
import { selectActiveRobotId, } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import { SES } from '../../../constants/accessRights';
import { ICloudTagProps } from './CloudTag.props';
import styles from './CloudTag.module.scss';

const CloudTag = ({ type, mainfilter, additionFilter, setShowElem }: ICloudTagProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const dataElemList = useAppSelector(selectDataList); // список конечных точек
	const endpointList = useAppSelector(selectEndpointList); // список элементов данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	// обработчик нажатия мыши на тег
	const clickHandler = (id: string): void => {
		// для элементов данных
		if (type === 'data') {
			isAccess(SES.DATA_GET) && activeRobotId && dispatch(getDataElem({ robotId: activeRobotId, elementId: id })); // получаем
		}
		// для конечных точек
		if (type === 'endpoints') {
			isAccess(SES.ENDPOINT_GET) && activeRobotId && dispatch(getEndpoint({ robotId: activeRobotId, endpointId: id })); // получаем
		}
		setShowElem(true); // открываем вкладку
	};

	return (
		<TagCloud
			// @ts-ignore
			className={cn(styles.cloud, {
				[styles.cloudRight]: type === 'endpoints',
			})}
			style={{
				fontFamily: 'sans-serif',
				fontSize: (_word, _index) => {
					// if (word?.children.toLowerCase().includes(filterByName.toLowerCase()) && filterByName !== '') return 10;
					// else return 8;
					return 8;
				},
				padding: 5,
				fontWeight: 'bold',
			}}
		>
			{type === 'data' ?
				dataElemList.data.map((item) => (
					<div className={cn({
						[styles.filterByEntryConditions]: item.name.toLowerCase().includes(mainfilter.toLowerCase()) && mainfilter !== '',
						[styles.reportColor]: item.rtype === 'report',
					})} key={item.id} onClick={() => clickHandler(item.id)}>
						{item.name}
					</div>
				))
				:
				endpointList.data.map((item) => {
					const containsCondition = (
						(mainfilter !== '' || additionFilter !== '')
						&&
						item.name.toLowerCase().includes(mainfilter.toLowerCase())
						&&
						// (item.entry.find(arrCondition => arrCondition.find(condition => additionFilter && dataElemList.dictionary[condition]?.toLowerCase().includes(additionFilter.toLowerCase()))) || additionFilter === '')
						item.entry.flat().map(condition => dataElemList.dictionary[condition]?.toLowerCase() || condition).find(condition => condition.includes(additionFilter?.toLowerCase() || ''))
					) ? true : false;
					return (
						<div className={cn({
							[styles.filterByEntryConditions]: containsCondition,
							[styles.reportColor]: item.type === 'report',
							[styles.transferColor]: item.type === 'transfer',
							[styles.finishColor]: item.type === 'finish',
						})} key={item.id} onClick={() => clickHandler(item.id)}>
							{item.name}
						</div>
					);
				})}
		</TagCloud>
	);
};

export default memo(CloudTag);
