import { Fragment, memo } from 'react';
import useTranslate from '../../hooks/useTranslate';
import ConditionRun from './ConditionRun/ConditionRun';
import { IConditionRunBlockProps } from './ConditionRunBlock.props';
import styles from './ConditionRunBlock.module.scss';

const ConditionRunBlock = ({ conditionDataBlock, conditionBlockIdx, arrayConditionsData, isDisable, isAvailable, changeFlg, setChangeFlg, conditionFor, addConditionRunHandler }: IConditionRunBlockProps): JSX.Element => {

	const translate = useTranslate(); // hook для перевода текста

	// сюда в конкретный блок по индексу надо передать список [конкретных индексов] строки условия - чтоб задисаблить и неотвайлибить

	return (
		<>
			<fieldset className={styles.conditionDataBlock}>
				<legend>{translate('title_conditions')}</legend>
				{conditionDataBlock?.map((conditionData, conditionIdx) => {
					if (conditionFor.for === 'endpoint' && Array.isArray(conditionFor.entryList) && conditionFor.entryList.includes(conditionData.id)) {
						// setEntryList(prev => prev.filter(dataElemId => dataElemId !== conditionData.id));
					}
					return (
						<Fragment key={conditionData.type + conditionData.id + conditionBlockIdx + conditionIdx + ((conditionFor.for === 'categoryAction' || conditionFor.for === 'categoryException') && conditionFor.categoryId)}>
							{/* блоки И */}
							<ConditionRun
								conditionData={conditionData}
								conditionBlockIdx={conditionBlockIdx}
								conditionIdx={conditionIdx}
								isDisable={isDisable || (conditionFor.for === 'endpoint' && Array.isArray(conditionFor.entryList) && conditionFor.entryList.includes(conditionData.id))}
								isAvailable={isAvailable && !(conditionFor.for === 'endpoint' && Array.isArray(conditionFor.entryList) && conditionFor.entryList.includes(conditionData.id))}
								changeFlg={changeFlg}
								setChangeFlg={setChangeFlg}
								conditionFor={conditionFor}
							/>
							{/* разделитель И */}
							{conditionDataBlock.length - 1 === conditionIdx ?
								isAvailable &&
								<button className={styles.conditionDataBlockLineButton} onClick={() => addConditionRunHandler(conditionBlockIdx)}>
									{translate('title_and')}
								</button>
								:
								<div className={styles.conditionDataBlockLine}>
									<span className={styles.conditionDataBlockLineTitle}>{translate('title_and')}</span>
								</div>
							}
						</Fragment>
					);
				})}
			</fieldset>
			{/* разделитель ИЛИ */}
			{arrayConditionsData.length - 1 !== conditionBlockIdx &&
				<div className={styles.conditionDataBlockLine}>
					<span className={styles.conditionDataBlockLineTitle}>{translate('title_or').toUpperCase()}</span>
				</div>
			}
		</>
	);
};

export default memo(ConditionRunBlock);
