import { useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, ColumnDef, getFilteredRowModel, SortingState, getSortedRowModel } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDataElem, getEndpoint, selectDataList, selectEndpointList } from '../../../store/sesSlice';
import { selectActiveRobotId } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { RobotDataRow } from '../../../types/tableTypes';
import { IRobotDataProps } from './RobotData.props';
import styles from './RobotData.module.scss';
import { DATA_ELEM_TYPE_OBJ, ENPOINT_TYPES_OBJ } from '../../../constants/robotConfigLists';

const RobotData = ({ type, mainfilter, additionFilter, setShowElem }: IRobotDataProps) => {
	const [data, setData] = useState<RobotDataRow[]>([]); // данные, преобразованные для работы в таблице
	const [sorting, setSorting] = useState<SortingState>([]); // сортированные данные
	const tableContainerRef = useRef<HTMLDivElement>(null); // ссылка на контейнер таблицы
	const tBodyRef = useRef<HTMLTableSectionElement>(null); // ссылка на body таблицы

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const dataElemList = useAppSelector(selectDataList); // список конечных точек
	const endpointList = useAppSelector(selectEndpointList); // список элементов данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за данными
	useEffect(() => {
		if (type === 'data') {
			setData(dataElemList.data.map(({ id, name, type, rtype }) => {
				return { id, name, type: translate(DATA_ELEM_TYPE_OBJ[type]), rtype: translate(ENPOINT_TYPES_OBJ[rtype]) };
			})); // преобразуем в формат таблицы
		} else {
			setData(endpointList.data.map(({ id, name, type, entry }) => {
				return { id, name, type: translate(ENPOINT_TYPES_OBJ[type]), entry: entry.flat().map(condition => dataElemList.dictionary[condition] || condition).toString() };
			})); // преобразуем в формат таблицы
		}
	}, [dataElemList.data, endpointList.data]);

	useEffect(() => {
		if (type === 'data') {
			table.getHeaderGroups()[0].headers[0].column.setFilterValue(mainfilter);
		} else {
			table.getHeaderGroups()[0].headers[0].column.setFilterValue(mainfilter);
			table.getHeaderGroups()[0].headers[3].column.setFilterValue(additionFilter);
		}
	}, [mainfilter, additionFilter]);

	// обработчик нажатия мыши на строку
	const clickHandler = (id: string): void => {
		// для элементов данных
		if (type === 'data') {
			isAccess(SES.DATA_GET) && activeRobotId && dispatch(getDataElem({ robotId: activeRobotId, elementId: id })); // получаем
		}
		// для конечных точек
		if (type === 'endpoints') {
			isAccess(SES.ENDPOINT_GET) && activeRobotId && dispatch(getEndpoint({ robotId: activeRobotId, endpointId: id })); // получаем
		}
		setShowElem(true); // открываем вкладку
	};

	const columns = useMemo<ColumnDef<RobotDataRow, string>[]>(() => [
		{
			id: 'name',
			header: translate('columnTitle_name'),
			accessorKey: 'name',
			size: 200,
		},
		{
			id: 'type',
			header: translate('columnTitle_type'),
			accessorKey: 'type',
			size: 50,
		},
		{
			id: 'rtype',
			header: translate('columnTitle_subType'),
			accessorKey: 'rtype',
			size: 50,
		},
		{
			id: 'entry',
			// header: translate('entry'),
			accessorKey: 'entry',
			// size: 100,
		},
	], []);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		filterFns: {
			myCustomFilter: () => {
				return false;
			}
		},
		enableColumnResizing: true, // изменение ширины столбца
		onSortingChange: setSorting, // для сортировки
		getSortedRowModel: getSortedRowModel(), // для сортировки
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(), // для фильтрации
		columnResizeMode: 'onChange', // изменение ширины столбца в runtime
	});

	return (
		<div className={styles.wrapper}>
			<div className={styles.container} ref={tableContainerRef}>
				<table className={styles.table} style={{ width: table.getCenterTotalSize() }}>
					<thead className={cn(styles.thead, {
						[styles.theadBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollTop > 0, // тень из под шапки и размытие под ним, когда скроллим таблицу
					})}>
						{table.getHeaderGroups().map(headerGroup => (
							<tr className={styles.tr} key={headerGroup.id}>
								{headerGroup.headers.map(header =>
									header.id !== 'entry' && !(type === 'endpoints' && header.id === 'rtype') && <th className={styles.th} {...{
										key: header.id,
										colSpan: header.colSpan,
										style: { width: header.getSize() }
									}}>
										{!header.isPlaceholder &&
											<div onClick={header.column.getToggleSortingHandler()} className={styles.thTitle}>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{{ asc: <FontAwesomeIcon icon={faArrowUp} />, desc: <FontAwesomeIcon icon={faArrowDown} /> }[header.column.getIsSorted() as string] ?? null}
											</div>
										}
									</th>
								)}
							</tr>
						))}
					</thead>
					<tbody className={styles.tbody} ref={tBodyRef}>
						{table.getRowModel().rows.map((row) => (
							<tr className={styles.tr} key={row.id}>
								{row.getVisibleCells().map(cell =>
									cell.column.id !== 'entry' && !(type === 'endpoints' && cell.column.id === 'rtype') && <td
										className={cn(styles.td, {
											[styles.tdName]: cell.column.id === 'name',
										})}
										{...{
											key: cell.id,
											style: {
												width: cell.column.getSize()
											},
										}}
										onClick={() => cell.column.id === 'name' && clickHandler(row.original.id)}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>

				{table.getRowModel().rows.length === 0 && <div className={styles.notFound}>{translate('title_notFound')}</div>}
			</div>
		</div>
	);
};

export default RobotData;
